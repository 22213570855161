import React from 'react';
import { graphql } from 'gatsby';
import BlogListLayout from '../BlogListLayout';

const BlogListAllViewTemplate = (props) => {
  return <BlogListLayout {...props} mainBlogsPage />;
};

export default BlogListAllViewTemplate;

export const query = graphql`
  fragment BlogPostNode on MarkdownRemarkEdge {
    node {
      frontmatter {
        date
        path
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        shortDescription
        authors {
          frontmatter {
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
            name
            surname
            path
          }
        }
        searchKeywords
        blogKeywords
        postCategories {
          frontmatter {
            postCategoryName
          }
        }
        postSubcategories {
          frontmatter {
            postSubcategoryName
          }
        }
        postTags {
          frontmatter {
            postTagName
          }
        }
      }
      wordCount {
        words
      }
    }
  }
  fragment TopPickNode on MarkdownRemarkEdge {
    node {
      frontmatter {
        date
        path
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        authors {
          frontmatter {
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
            name
            surname
            path
          }
        }
      }
    }
  }
`;

export const blogListAllQuery = graphql`
  query BlogListAll($limit: Int, $skip: Int, $topPicksPaths: [String!]!, $latestTaggedBlogPostsPaths: [String!]!) {
    blogPosts: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        ...BlogPostNode
      }
    }
    latestTaggedBlogPosts: allMarkdownRemark(
      filter: { frontmatter: { path: { in: $latestTaggedBlogPostsPaths } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        ...BlogPostNode
      }
    }
    topPicks: allMarkdownRemark(
      filter: { frontmatter: { path: { in: $topPicksPaths } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        ...TopPickNode
      }
    }
  }
`;
